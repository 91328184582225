"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findStatusIntegracaoZap = exports.findSaldoIntegracaoZap = exports.findPainelCliente = exports.findPainelFuncionario = void 0;
const core_1 = require("./core");
function findPainelFuncionario(page = 1, search) {
    return core_1.get('VwPainel', 'painelFuncionario', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findPainelFuncionario = findPainelFuncionario;
function findPainelCliente(page = 1, search) {
    return core_1.get('VwPainel', 'painelCliente', Object.assign({ pg: page }, search))
        .then(({ dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findPainelCliente = findPainelCliente;
function findSaldoIntegracaoZap(page = 1, search) {
    return core_1.get('VwPainel', 'consultaSaldoIntegracaoZap', Object.assign({ pg: page }, search))
        .then(({ msg, dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            mensagem: msg,
            elements: dados,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findSaldoIntegracaoZap = findSaldoIntegracaoZap;
function findStatusIntegracaoZap(page = 1, search) {
    return core_1.get('VwPainel', 'consultaStatusIntegracaoZap', Object.assign({ pg: page }, search))
        .then(({ msg, dados, paginacao }) => {
        if (dados.length === 0)
            return {
                elements: [],
                elementsPerPage: 0,
                totalElements: 0,
                currentPage: 0,
                totalPages: 0
            };
        return {
            elements: dados,
            mensagem: msg,
            elementsPerPage: paginacao.totalRegPorPagina,
            totalElements: paginacao.totalRegEncontrados,
            currentPage: paginacao.pagAtual,
            totalPages: paginacao.qtdPag
        };
    });
}
exports.findStatusIntegracaoZap = findStatusIntegracaoZap;
